const React = require("react")
const { QueryClient, QueryClientProvider } = require('react-query')
const queryClient = new QueryClient()
const { ReactQueryDevtools } = require('react-query/devtools')

exports.wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
        {element}
    </QueryClientProvider>
  )
}